/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Layout, Rate, Row, Steps, Table, Typography } from 'antd';
import { Radio, Select, Space, Button, Modal, Carousel } from 'antd';
import type { RadioChangeEvent } from 'antd';
import axios from 'axios';

import '../assets/css/Herbicidas.scss';
import Header from './Header';
import { DoubleRightOutlined } from '@ant-design/icons';
import Column from 'antd/es/table/Column';
import { ColumnsType } from 'antd/es/table';


function Herbicidas() {
  const {  Text, Title } = Typography;
  const { Footer, Content } = Layout;
  const carouselM:any = useRef();
  const carouselS:any = useRef();

/*
  const posicionamientoUrl = 'http://localhost:3000/posicionamiento.json';
  const rotacionesUrl = 'http://localhost:3000/rotaciones.json';
  const herbicidasSolosURL = 'http://localhost:3000/herbicidasSolos.json';
  const herbicidasMezclaURL = 'http://localhost:3000/herbicidasMezcla.json';
  const rotacionesNombresURL = 'http://localhost:3000/rotacionesNombres.json';
*/
  const posicionamientoUrl = 'https://herbicidas.activadekalb.com/posicionamiento.json';
  const rotacionesUrl = 'https://herbicidas.activadekalb.com/rotaciones.json';
  const herbicidasSolosURL = 'https://herbicidas.activadekalb.com/herbicidasSolos.json';
  const herbicidasMezclaURL = 'https://herbicidas.activadekalb.com/herbicidasMezcla.json';
  const rotacionesNombresURL = 'https://herbicidas.activadekalb.com/rotacionesNombres.json';

  interface recomendationType {
    herbName: string,
    valueHerbicida0: string,
    valueHerbicida0name: string,
    valueHerbicida0stars: string,
    valueHerbicida1: string,
    valueHerbicida1name: string,
    valueHerbicida1stars: string,
    valueHerbicida2: string,
    valueHerbicida2name: string,
    valueHerbicida2stars: string,
    valueHerbicida3: string,
    valueHerbicida3name: string,
    valueHerbicida3stars: string,
  }

  interface herbicidasType {
    herbName: string,
    herbicideName: string,
    value: number,
  }

  interface herbicidasPromedioType {
    herbicideName: string,
    herbicidas: herbicidasType[],
    sum: number,
    promedio: number,
    stars: number,
  }

  interface posicionamientoType{
    Zona: string,
    Laudis: string,
    Capreno: string,
    Monsoon: string,
    herbName: string,
  }

  interface appStateType {
    loading: boolean,
    herbicidas: [any],
    rotaciones: [any],
    herbicidasSolos: [],
    herbicidasMezcla: [],
    rotacionesNombres: [],
    empty: boolean,
  }

  interface RecordType {
    label: string,
    value: string,
  }

  interface moreInfoType {
    herbicideName: string,
    r: string,
    lp: string,
    nd: string,
    ls: string,
    pv: string,
    meses: string,
  }

  /*
  const [posicionamientoInic, setPosicionamientoInic] = useState<posicionamientoType>({
    Zona: '',
    Laudis: '',
    Capreno: '',
    Monsoon: '',
    herbName: '',
  });
  */

  const [appState, setAppState] = useState<appStateType>({
    loading: false,
    herbicidas: [{}],
    rotaciones: [{}],
    herbicidasSolos: [],
    herbicidasMezcla: [],
    rotacionesNombres: [],
    empty: true,
  });

  const [zoneOptions, setZoneOptions] = useState<RecordType[]>([]);
  const [badHerbsOptions, setBadHerbsOptions] = useState<RecordType[]>([]);
  const [recomendation, setRecomendation] = useState<recomendationType[]>([]);
  const [recomendationColums, setRecomendationColums] = useState<ColumnsType<recomendationType>>();
  const [cropOptions, setCropOptions] = useState<RecordType[]>([]);

  const limitationOptions: RecordType[] = [
    { label: 'SI', value: 'si' },
    { label: 'NO', value: 'no' },
  ];

  const [current, setCurrent] = useState(0);
  const [limitationValue, setLimitationValue] = useState('no');
  const [nextCrop, setNextCrop] = useState('');
  const [zone, setZone] = useState('');
  const [badHerbs, setBadHerbs] = useState<string[]>([]);
  const [hierbasSolasBests, setHierbasSolasBests] = useState<herbicidasPromedioType[]>([]);
  const [hierbasMezclaBests, setHierbasMezclaBests] = useState<herbicidasPromedioType[]>([]);
  const [currentCarouselStepS, setCurrentCarouselStepS] = useState(0);
  const [currentCarouselStepM, setCurrentCarouselStepM] = useState(0);

  useEffect(() => { // Get ALL JSONs
    if(!appState.empty) return;
    setAppState({ loading: true, herbicidas: [{}], rotaciones: [{}], herbicidasSolos: [], herbicidasMezcla: [], rotacionesNombres: [], empty: true});
    try {
      axios.get(posicionamientoUrl).then(resPos => {
        axios.get(rotacionesUrl).then(resRot => {
          axios.get(herbicidasSolosURL).then(resHerbS => {
            axios.get(herbicidasMezclaURL).then(resHerbM => {
              axios.get(rotacionesNombresURL).then(resRotNom => {
                setAppState({ loading: false, herbicidas: resPos.data, rotaciones: resRot.data, herbicidasSolos: resHerbS.data, herbicidasMezcla: resHerbM.data, rotacionesNombres: resRotNom.data, empty: false});
              })
            })
          })
        })
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response?.data);
      }else{
        console.log(error);
      }
    }
  }, [appState.empty]);

  useEffect(() => { // Add Zona Options
    if(!appState.empty){
      const items = addArrayItems("Zona");
      setZoneOptions(orderArrayByLabel(items));
    }
  }, [appState.empty]);

  useEffect(() => { // Add Bad Herbs Options
    if(!appState.empty){
      const items = addArrayItems("herbName");
      setBadHerbsOptions(orderArrayByLabel(items));
    }
  }, [appState.empty]);

  useEffect(() => { // Add Crops Options (siguiente cultivo)
    if(!appState.empty){
      const items: RecordType[] = [];
      let itemsAux: RecordType = { label: '', value: '' };
      appState?.rotacionesNombres?.map((key) => {
        itemsAux = { label: key , value: key }
        if (!isItemInArray(items, itemsAux)) items.push(itemsAux);
        return itemsAux;
      })
      setCropOptions(orderArrayByLabel(items));
    }
  }, [appState.empty]);


  const isItemInArray = (array: RecordType[], item:RecordType) => {
    for (let i = 0; i < array.length; i++) {
      // This if statement depends on the format of your array
      if (array[i].label.trim().toLowerCase() === item.label.trim().toLowerCase() && array[i].value.trim().toLowerCase() === item.value.trim().toLowerCase()) return true;   // Found it
    }
    return false;   // Not found
  };


  function replaceString(str:string){
    str = str.replace('_', ' + ');
    str = str.replace('\n', ' ');
    return str;
  }

  function toTitleCase(str:string) {
    let aux = str;
    if(zone.toLowerCase() === 'portugal'){ //Fix para nombres de Herbicidas en Portugal
      aux = aux.replace("Oizysa", "Dicamba");
      aux = aux.replace("Cubix", "Option");
      aux = aux.replace("Fluva", "Mesotriona");
    }
    return replaceString(aux).toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  const addArrayItems = ((searchKey: keyof posicionamientoType) => {
    const items: RecordType[] = [];
    let itemsAux: RecordType = { label: '', value: '' };
    appState?.herbicidas?.map((key) => {
      itemsAux = { label: key[searchKey] , value: key[searchKey] }
      if (!isItemInArray(items, itemsAux)) items.push(itemsAux);
      return itemsAux;
    })
    return items;
  })

  const getHerbicidaValueArray = ((searchKey: keyof posicionamientoType, zoneHerbsArray: posicionamientoType[]) => {
    const items: herbicidasType[] = [];
    let itemsAux: herbicidasType = { herbName: '', herbicideName: '', value: 0 };
    zoneHerbsArray?.map((zoneHerbKey) => {
        //console.log(searchKey +' -> '+zoneHerbKey[searchKey]);
        itemsAux = { herbName:zoneHerbKey.herbName, herbicideName: searchKey , value: (zoneHerbKey[searchKey])? parseFloat(zoneHerbKey[searchKey]) : 0 }
        items.push(itemsAux);
        //console.log(itemsAux);
        return itemsAux;
      })
    return items;
  })

  const getHerbicidasArray = ((zoneHerbs: posicionamientoType[], herbicidasArray: any[]) => {
    const herbicidas: herbicidasType[] = [];
    let aux: herbicidasType[] = [];
    herbicidasArray?.map((herbKey) => {
      //console.log(limitationValue +'  -> '+herbKey.trim().toLowerCase()+'  -> '+herbKey.trim().toLowerCase().indexOf('monsoon')+'  --> '+herbKey.trim().toLowerCase().indexOf('capreno'))
      if(limitationValue === 'si' && (herbKey.trim().toLowerCase().indexOf('monsoon') !== -1 || herbKey.trim().toLowerCase().indexOf('capreno') !== -1)) return '';
      aux = getHerbicidaValueArray(herbKey,zoneHerbs);
      aux?.map((auxKey) => { herbicidas.push(auxKey); return '';})
      return '';
    })
    return (herbicidas);
  })

  const getHerbicidasSolosArray = ((zoneHerbs: posicionamientoType[] ) => {
    return (getHerbicidasArray(zoneHerbs, appState.herbicidasSolos));
  })

  const getHerbicidasMezclaArray = ((zoneHerbs: posicionamientoType[]) => {
    return (getHerbicidasArray(zoneHerbs, appState.herbicidasMezcla));
  })

  const orderArrayByPromedio = ((array: herbicidasPromedioType[]) => {
    return array.sort(function (a, b) {
      if (a.promedio >= b.promedio) return -1;
      if (a.promedio < b.promedio) return 1;
      return 0;
    })
  })

  const orderArrayBySuma = ((array: herbicidasPromedioType[]) => {
    return array.sort(function (a, b) {
      if (a.sum >= b.sum) return -1;
      if (a.sum < b.sum) return 1;
      return 0;
    })
  })

  const orderArrayByLabel = ((array: RecordType[]) => {
    return array.sort(function (a, b) {
      if (a.label >= b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    })
  })

  // Se obtiene el promedio, pero finalmente en vez de usar promedio se usa la suma total.
  const getHerbicidasPromedioArray = ((herbicidasArray: any[], type: string) => {
    const herbicidas: herbicidasPromedioType[] = [];
    let auxHerb: herbicidasPromedioType = { herbicideName: '', herbicidas: [], sum: 0, promedio: 0, stars: 0 };
    let auxHerbProm: herbicidasPromedioType = { herbicideName: '', herbicidas: [], sum: 0, promedio: 0, stars: 0 };
    let count:number = 0;
    let sum:number = 0;
    //console.log(herbicidasArray)
    herbicidasArray?.map((herbKey) => {
      auxHerb = { herbicideName: '', herbicidas: [], sum: 0, promedio: 0, stars: 0 };
      count = 0;
      sum = 0;
      auxHerb.herbicidas = [];
      auxHerbProm.herbicidas = [];
      herbicidasArray?.map((herbAuxKey) => {
        if(herbAuxKey.herbicideName === herbKey.herbicideName){
          count++;
          sum += herbAuxKey.value;
          auxHerbProm.herbicidas.push(herbAuxKey);
        }
        auxHerbProm.promedio = sum / count;
        auxHerbProm.sum = sum;
        return auxHerbProm;
      })
      auxHerb.herbicideName = herbKey.herbicideName;
      auxHerb.promedio = auxHerbProm.promedio;
      auxHerb.sum = auxHerbProm.sum;
      auxHerb.stars = getStars(auxHerbProm.sum,type);
      auxHerbProm.herbicidas?.map((herbAuxKey) => {
        auxHerb.herbicidas.push(herbAuxKey);
        return herbAuxKey;
      })
      if(!herbicidas.find(herbKey => herbKey.herbicideName === auxHerb.herbicideName)) herbicidas.push(auxHerb);
      //return herbicidas;
    })
    return herbicidas;
  })

  const getZoneHerbs = ((zone: string, badHerbs: string[]) => {
    // Generar arreglo con la zona
    const zoneHerbsArray: posicionamientoType[] = [];
    appState?.herbicidas?.map((key) => {
      if(key.Zona.trim().toLowerCase() === zone.trim().toLowerCase()) zoneHerbsArray.push(key);
      return zoneHerbsArray;
    })
    //console.log(zoneHerbsArray);

    // Generar arreglo con las malas hierbas de la zona
    const badHerbsArray: posicionamientoType[] = [];
    zoneHerbsArray?.map((keyZone) => {
      badHerbs?.map((keyBadHerbs) => {
        if(keyZone.herbName.trim().toLowerCase() === keyBadHerbs.trim().toLowerCase()) badHerbsArray.push(keyZone);
        return badHerbsArray;
      })
      return keyZone;
    })
    return badHerbsArray;
  })


  const handleChangeLimitations = ({ target: { value } }: RadioChangeEvent) => {
    setLimitationValue(value);
    setCurrent(1);
  };

  const handleNextCrop = (value: string) => {
    setNextCrop(value);
    setCurrent(2);
  };

  const handleZone = (value: string) => {
    setZone(value);
    setCurrent(3);
  };

  const handleBadHerbs = (value: string[]) => {
    setBadHerbs(value);
  };

  const onChange = (value: number) => {
    setCurrent(value);
  };

  const generateRecomendationColums = (array: herbicidasPromedioType[]) => {
    const columns: ColumnsType<recomendationType> = [
      {
        title: '',
        width: 150,
        dataIndex: 'herbName',
        key: 'herbName',
        fixed: 'left',
      },
      (array[0]?.herbicidas[0]?.herbicideName)?
      {
        title: toTitleCase(array[0]?.herbicidas[0]?.herbicideName),
        width: 200,
        dataIndex: 'valueHerbicida0stars',
        key: '1',
        align: 'center',
        //render: (value) => <>{value} <Rate disabled allowHalf value={parseFloat(value)} /></>,
        render: (value) => <Rate disabled allowHalf value={parseFloat(value)} />,
      }:{},
      (array[1]?.herbicidas[0]?.herbicideName)?
      {
        title: toTitleCase(array[1]?.herbicidas[0]?.herbicideName),
        width: 200,
        dataIndex: 'valueHerbicida1stars',
        key: '2',
        align: 'center',
        //render: (value) => <>{value} <Rate disabled allowHalf value={parseFloat(value)} /></>,
        render: (value) => <Rate disabled allowHalf value={parseFloat(value)} />,
      }:{},
      (array[2]?.herbicidas[0]?.herbicideName)?
      {
        title: toTitleCase(array[2]?.herbicidas[0]?.herbicideName),
        width: 200,
        dataIndex: 'valueHerbicida2stars',
        key: 'valueHerbicida2',
        align: 'center',
        //render: (value) => <>{value} <Rate disabled allowHalf value={parseFloat(value)} /></>,
        render: (value) => <Rate disabled allowHalf value={parseFloat(value)} />,
      }:{},
      (array[3]?.herbicidas[0]?.herbicideName)?
      {
        title: toTitleCase(array[3]?.herbicidas[0]?.herbicideName),
        width: 200,
        dataIndex: 'valueHerbicida3stars',
        key: 'valueHerbicida3',
        align: 'center',
        //render: (value) => <>{value} <Rate disabled allowHalf value={parseFloat(value)} /></>,
        render: (value) => <Rate disabled allowHalf value={parseFloat(value)} />,
      }:{},
    ];

  //console.log(columns);
  setRecomendationColums(columns);
  }

  //Aplicar Logica: Si un producto solo tiene más estrellas que la mezcla de ese producto con otro, en esa mezcla se ponen las estrellas del producto solo (la mayor).
  const fixRecomendationStars = (recomendations: recomendationType[]) => {
    //console.error("ACAAA:");
    let herbAuxVal: recomendationType[] = recomendations;
    let cont:number = 0;
    //console.log(recomendations); //Se renderiza cuando se hace click y no sirve para ver el verdadero valor
    recomendations?.map((aux) => {
      //console.log(aux.herbName);
      if(aux?.valueHerbicida0name?.trim().toLowerCase().indexOf(aux?.valueHerbicida2name?.trim().toLowerCase()) !== -1){
        //console.log("A: S0:"+aux.valueHerbicida0stars+ ' > S2:' + aux?.valueHerbicida2stars + ' >> AuxS0:'+ herbAuxVal[cont].valueHerbicida0stars)
        if(aux?.valueHerbicida2stars > aux?.valueHerbicida0stars) herbAuxVal[cont].valueHerbicida0stars = aux?.valueHerbicida2stars;
        //console.log("D: S0:"+aux.valueHerbicida0stars+ ' > S2:' + aux?.valueHerbicida2stars +' >> AuxS0:'+ herbAuxVal[cont].valueHerbicida0stars + ' >> AuxSoloName: '+  herbAuxVal[cont].valueHerbicida2name)
      }
      if(aux?.valueHerbicida0name?.trim().toLowerCase().indexOf(aux?.valueHerbicida3name?.trim().toLowerCase()) !== -1){
        //console.log("A: S3:"+aux.valueHerbicida3stars+ ' > S0:' + aux?.valueHerbicida0stars + ' >> '+ herbAuxVal[cont].valueHerbicida0stars)
        if(aux?.valueHerbicida3stars > aux?.valueHerbicida0stars) herbAuxVal[cont].valueHerbicida0stars = aux?.valueHerbicida3stars;
        //console.log("D: S3:"+aux.valueHerbicida3stars+ ' > S0:' + aux?.valueHerbicida0stars +' >> AuxS0:'+ herbAuxVal[cont].valueHerbicida0stars + ' >> AuxSoloName: '+  herbAuxVal[cont].valueHerbicida3name)
      }
      if(aux?.valueHerbicida1name?.trim().toLowerCase().indexOf(aux?.valueHerbicida2name?.trim().toLowerCase()) !== -1){
        //console.log("A: S1:"+aux.valueHerbicida1stars+ ' > S2:' + aux?.valueHerbicida2stars + ' >> '+ herbAuxVal[cont].valueHerbicida1stars)
        if(aux?.valueHerbicida2stars > aux?.valueHerbicida1stars) herbAuxVal[cont].valueHerbicida1stars = aux?.valueHerbicida2stars;
        //console.log("D: S1:"+aux.valueHerbicida1stars+ ' > S2:' + aux?.valueHerbicida2stars +' >> AuxS1:'+ herbAuxVal[cont].valueHerbicida1stars + ' >> AuxSoloName: '+  herbAuxVal[cont].valueHerbicida2name)
      }
      if(aux?.valueHerbicida1name?.trim().toLowerCase().indexOf(aux?.valueHerbicida3name?.trim().toLowerCase()) !== -1){
        //console.log("A: S3:"+aux.valueHerbicida3stars+ ' > S1:' + aux?.valueHerbicida1stars + ' >> '+ herbAuxVal[cont].valueHerbicida0stars)
        if(aux?.valueHerbicida3stars > aux?.valueHerbicida1stars) herbAuxVal[cont].valueHerbicida1stars = aux?.valueHerbicida3stars;
        //console.log("D: S3:"+aux.valueHerbicida3stars+ ' > S1:' + aux?.valueHerbicida1stars +' >> AuxS1:'+ herbAuxVal[cont].valueHerbicida1stars + ' >> AuxSoloName: '+  herbAuxVal[cont].valueHerbicida3name)
      }
      cont++;
    })

    //console.log(herbAuxVal);
    return herbAuxVal;
  }


  const generateRecomendationArray = (array: herbicidasPromedioType[], badHerbs: string[]) => {
    const herbicidas: recomendationType[] = [];

    let herbAuxVal: recomendationType = {
      herbName: '',
      valueHerbicida0: '',
      valueHerbicida0name: '',
      valueHerbicida0stars: '',
      valueHerbicida1: '',
      valueHerbicida1name: '',
      valueHerbicida1stars: '',
      valueHerbicida2: '',
      valueHerbicida2name: '',
      valueHerbicida2stars: '',
      valueHerbicida3: '',
      valueHerbicida3name: '',
      valueHerbicida3stars: '',
    };

    let aux0, aux1, aux2, aux3;

    //console.log(array);
    //console.log(badHerbs);
    badHerbs?.map((keyBadHerbs) => {
      array?.map(() => {
        if (herbicidas.find(h => h.herbName.trim().toLowerCase() === keyBadHerbs.trim().toLowerCase())) return null;
        aux0 = array[0]?.herbicidas?.find(herbicide => herbicide.herbName.trim().toLowerCase() === keyBadHerbs.trim().toLowerCase())?.value;
        aux1 = array[1]?.herbicidas?.find(herbicide => herbicide.herbName.trim().toLowerCase() === keyBadHerbs.trim().toLowerCase())?.value;
        aux2 = array[2]?.herbicidas?.find(herbicide => herbicide.herbName.trim().toLowerCase() === keyBadHerbs.trim().toLowerCase())?.value;
        aux3 = array[3]?.herbicidas?.find(herbicide => herbicide.herbName.trim().toLowerCase() === keyBadHerbs.trim().toLowerCase())?.value;
        herbAuxVal = {
          herbName: keyBadHerbs,
          valueHerbicida0: (aux0)? aux0.toString(): '',
          valueHerbicida0name: array[0]?.herbicidas[0]?.herbicideName,
          valueHerbicida0stars: (aux0)? getStars(aux0,'mezcla').toString(): '1',
          valueHerbicida1: (aux1)? aux1.toString(): '',
          valueHerbicida1name: array[1]?.herbicidas[0]?.herbicideName,
          valueHerbicida1stars: (aux1)? getStars(aux1,'mezcla').toString(): '1',
          valueHerbicida2: (aux2)? aux2.toString(): '',
          valueHerbicida2name: array[2]?.herbicidas[0]?.herbicideName,
          valueHerbicida2stars: (aux2)? getStars(aux2,'solo').toString(): '1',
          valueHerbicida3: (aux3)? aux3.toString(): '',
          valueHerbicida3name: array[3]?.herbicidas[0]?.herbicideName,
          valueHerbicida3stars: (aux3)? getStars(aux3,'solo').toString(): '1',
        }
        herbicidas.push(herbAuxVal);
      })
    })

    setRecomendation(fixRecomendationStars(herbicidas));
    //console.log(herbicidas);
  };


  const info = (herbicidas: herbicidasPromedioType) => {
    let herbNameA = herbicidas.herbicideName;
    let herbNameB = '';
    if(herbicidas?.herbicideName?.split('+').length === 2){
      herbNameA = herbicidas?.herbicideName?.split('+')[0].trim();
      herbNameB = herbicidas?.herbicideName?.split('+')[1].trim();
    }

    let msg:string = 'Se aconseja una previa labor de vertedera. En cuanto al número de días de intervalo, la tercera compañía de la que proviene el producto, no detalla número de días';
    if(nextCrop.trim().toLowerCase().indexOf('cereales') !== -1) msg = 'Esperar 3 semanas para sembrar cereales de invierno. Siempre con una previa labor de vertedera.';
    if(nextCrop.trim().toLowerCase().indexOf('colza') !== -1) msg = 'Esperar 40 días para sembrar colza. Siempre con una previa labor de vertedera.';

    const moreInfoA:moreInfoType = getMoreInfo(herbNameA);
    const moreInfoB:moreInfoType = getMoreInfo(herbNameB);

    const textInfoA = <>
      <b style={{ color: '#fad201' }}>{(moreInfoA.herbicideName !== '')? <br/>:''}{(moreInfoA.herbicideName !== '')? toTitleCase(moreInfoA.herbicideName):''}{(moreInfoA.herbicideName !== '')? <br/>:''}</b>
      <br/><b>¿Podemos recomendarlo oficialmente y, en caso de problemas con el siguiente cultivo, el agricultor tiene derecho a reclamar?</b>
      {(moreInfoA.r !== '')? <br/>:''}{(moreInfoA.r !== '')? moreInfoA.r:''}{(moreInfoA.r !== '')? <br/>:''}
      <br/><b>¿Alguna consideración adicional?</b>
      {(moreInfoA.lp !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoA.lp}</span>:''}
      {(moreInfoA.nd !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoA.nd}</span>:''}
      {(moreInfoA.ls !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoA.ls}</span>:''}
      {(moreInfoA.pv !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoA.pv}</span>:''}
      {(moreInfoA.meses !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoA.meses}</span>:''}
      {(moreInfoA.herbicideName.trim().toLowerCase().indexOf('oizysa') !== -1 || moreInfoA.herbicideName.trim().toLowerCase().indexOf('dicamba') !== -1)?
        <span><br/><DoubleRightOutlined style={{ color: '#1677ff' }}/>{msg}</span>
        :
        (moreInfoA.nd === '') && (moreInfoA.lp === '') && (moreInfoA.ls === '') && (moreInfoA.pv === '') && (moreInfoA.meses === '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>Sin consideraciones</span>:''}
    </>;

    let textInfoB = <></>;
    if (herbicidas?.herbicideName?.split('+').length === 2) textInfoB = <>
        <br/><br/><hr/>
        <b style={{ color: '#fad201' }}>{(moreInfoB.herbicideName !== '')? <br/>:''}{(moreInfoB.herbicideName !== '')? toTitleCase(moreInfoB.herbicideName):''}{(moreInfoB.herbicideName !== '')? <br/>:''}</b>
        <br/><b>¿Podemos recomendarlo oficialmente y, en caso de problemas con el siguiente cultivo, el agricultor tiene derecho a reclamar?</b>
        {(moreInfoB.r !== '')? <br/>:''}{(moreInfoB.r !== '')? moreInfoB.r:''}{(moreInfoB.r !== '')? <br/>:''}
        <br/><b>¿Alguna consideración adicional?</b>
        {(moreInfoB.nd !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoB.nd}</span>:''}
        {(moreInfoB.lp !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoB.lp}</span>:''}
        {(moreInfoB.ls !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoB.ls}</span>:''}
        {(moreInfoB.pv !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoB.pv}</span>:''}
        {(moreInfoB.meses !== '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>{moreInfoB.meses}</span>:''}
        {(moreInfoB.herbicideName.trim().toLowerCase().indexOf('oizysa') !== -1 || moreInfoB.herbicideName.trim().toLowerCase().indexOf('dicamba') !== -1)?
          <span><br/><DoubleRightOutlined style={{ color: '#1677ff' }}/>{msg}</span>
          :
          (moreInfoB.nd === '') && (moreInfoB.lp === '') && (moreInfoB.ls === '') && (moreInfoB.pv === '') && (moreInfoB.meses === '')? <span> <br/> <DoubleRightOutlined style={{ color: '#1677ff' }}/>Sin consideraciones</span>:''}
    </>;

    Modal.info({
      title: 'Información de Rotación de cultivos',
      content: (
        <div style={{ marginLeft: "-30px", display: "block", textAlign: "justify" }}>
          {textInfoA}
          {textInfoB}
        </div>
      ),
      okText: "Cerrar",
      onOk() {},
      centered: true,
    });
  };

  const getMoreInfo = (herbicideName: string) => {
    let auxMoreInfo:moreInfoType = {
      herbicideName,
      r: 'Sin datos específicos.',
      nd: '',
      lp: '',
      ls: '',
      pv: '',
      meses: ''
    };
    if(herbicideName.length === 0) return auxMoreInfo;
    let meses:string = '';
    let auxMeses = '';
    //console.log(appState);
    appState?.rotaciones?.map((keyRot) => {
      if(keyRot?.Herbicida?.trim().toLowerCase().indexOf(herbicideName.trim().toLowerCase()) !== -1 ){
        auxMoreInfo.herbicideName = herbicideName;
        auxMoreInfo.r = (keyRot[nextCrop] && keyRot[nextCrop].trim().toLowerCase().indexOf('si') !== -1)?
          (keyRot[nextCrop] && keyRot[nextCrop].trim().toLowerCase().indexOf(' sr') !== -1)?
            'Tenemos suficientes datos contrastados y sí podemos recomendar.'
            : 'Tenemos datos, pero no los suficientes. Facilitar información al agricultor si procede pero sin aceptar responsabilidades por parte de BCS.'
          : (keyRot[nextCrop] && keyRot[nextCrop].trim().toLowerCase().indexOf(' sr') !== -1)?
            'Con información suficiente que nos dice que evitemos la rotación con el cultivo indicado.'
            : 'Sin datos específicos. La rotación con el cultivo citado no se recomienda.';
        auxMoreInfo.nd = (keyRot[nextCrop] && keyRot[nextCrop].trim().toLowerCase().indexOf(' nd') !== -1)? 'No se recomienda siembra directa.':'';
        auxMoreInfo.lp = (keyRot[nextCrop] && keyRot[nextCrop].trim().toLowerCase().indexOf(' lp') !== -1)? 'Se recomienda una labor de vertedera profunda, especialmente en siembras otoñales y cuando la pluviometría haya sido inferior a la media de la zona.':'';
        auxMoreInfo.ls = (keyRot[nextCrop] && keyRot[nextCrop].trim().toLowerCase().indexOf(' ls') !== -1)? 'No se recomienda siembra directa, especialmente en siembras otoñales y cuando la pluviometría haya sido inferior a la media de la zona.':'';
        auxMoreInfo.pv = (keyRot[nextCrop] && keyRot[nextCrop].trim().toLowerCase().indexOf(' pv') !== -1)? 'Variedad de Primavera.':'';
        meses = (keyRot[nextCrop] && keyRot[nextCrop]?.trim()?.toLowerCase().indexOf('(') !== -1)? keyRot[nextCrop].trim().toLowerCase().split('(').pop().split(')')[0] : null;
        if(keyRot[nextCrop] && keyRot[nextCrop].trim().toLowerCase().indexOf('\n') !== -1){
          auxMeses = keyRot[nextCrop].split(' ');
          auxMeses = (auxMeses && auxMeses[1])? replaceString(auxMeses[1]) : '';
          meses = auxMeses;
        }

        auxMoreInfo.meses = (meses)? 'Se recomienda esperar '+meses+' meses antes de implantar el siguiente cultivo de rotación.':'';
        //console.log(keyRot);
        //console.log(keyRot[nextCrop]);
        //console.log(auxMoreInfo);
      }
    })
    return auxMoreInfo;
  };

  const getResult = () => {
    //console.log('Limitations:', limitationValue);
    //console.log('nextCrop:', nextCrop);
    //console.log('zone:', zone);
    //console.log('badHerbs:', badHerbs);

    setCurrentCarouselStepS(0);
    setCurrentCarouselStepM(0);

    const zoneHerbs = getZoneHerbs(zone, badHerbs);
    //console.log(zoneHerbs);

    const herbicidasSolos = getHerbicidasSolosArray(zoneHerbs);
    //console.log(herbicidasSolos);

    const herbicidasMezcla = getHerbicidasMezclaArray(zoneHerbs);
    //console.log(herbicidasMezcla);

    const herbicidasSolosPromedio = getHerbicidasPromedioArray(herbicidasSolos,'solo');
    //console.log(orderArrayBySuma(herbicidasSolosPromedio));
    setHierbasSolasBests(orderArrayBySuma(herbicidasSolosPromedio));

    const herbicidasMezclaPromedio = getHerbicidasPromedioArray(herbicidasMezcla,'mezcla');
    //console.log(orderArrayBySuma(herbicidasMezclaPromedio));
    setHierbasMezclaBests(orderArrayBySuma(herbicidasMezclaPromedio));

    const recomendation = orderArrayBySuma(herbicidasMezclaPromedio).slice(0,2).concat(orderArrayBySuma(herbicidasSolosPromedio).slice(0,2));
    generateRecomendationArray(recomendation, badHerbs);
    generateRecomendationColums(recomendation);

  }

  const getStars = (sum: number, type: string) => {
    //return sum;
    if(!sum) return 1;
    if(type.trim().toLowerCase().indexOf('solo') !== -1){
      if(sum >= 4) return 5;
      if(sum >= 3.5 && sum < 4) return 4;
      if(sum >= 2 && sum < 3.5) return 3;
      if(sum >= 1 && sum < 2) return 2;
      if(sum < 1) return 1;
    }
    if(type.trim().toLowerCase().indexOf('mezcla') !== -1){
      if(sum >= 6) return 5;
      if(sum >= 4.5 && sum < 6) return 4;
      if(sum >= 3 && sum < 4.5) return 3;
      if(sum >= 2 && sum < 3) return 2;
      if(sum < 2) return 1;
    }
    return sum;
  }

  const onChangeCarouselS = (currentSlide: number) => { setCurrentCarouselStepS(currentSlide); };
  const onChangeCarouselM = (currentSlide: number) => { setCurrentCarouselStepM(currentSlide); };

  return (
  <>
    <Layout className='wrapper'>
      <Header title={'COMPARADOR EFICACIA HERBICIDAS'} />
      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <div className="site-layout-background" style={{ padding: 0, minHeight: 360 }}>
          <Content className="content">
            <Space direction="vertical">
              <Steps
                current={current}
                onChange={onChange}
                direction="vertical"
                items={[
                  {
                    title: <Text className="textContent">¿El agricultor tiene limitaciones con la <br/><b>Tiencarbazona</b> por la rotación de cultivos?</Text>,
                    description:
                      <Radio.Group
                        options={limitationOptions}
                        onChange={handleChangeLimitations}
                        value={limitationValue}
                        defaultValue={limitationValue}
                        optionType="button"
                        disabled={ (current !== 0) ? true : false}
                        //disabled={true}
                      />,
                  },
                  {
                    title: <Text className="textContent">Siguiente cultivo después de maíz</Text>,
                    description:
                      <Select
                        placeholder='Siguiente Cultivo'
                        style={{ width: "100%" }}
                        onChange={handleNextCrop}
                        options={cropOptions}
                        defaultValue={nextCrop}
                        disabled={ (current !== 1) ? true : false}
                      />,
                  },
                  {
                    title: <Text className="textContent">Zona</Text>,
                    description:
                      <Select
                        placeholder='Zona'
                        style={{ width: "100%" }}
                        onChange={handleZone}
                        options={zoneOptions}
                        defaultValue={zone}
                        disabled={ (current !== 2) ? true : false}
                      />,
                  },
                  {
                    title: <Text className="textContent">Malas hierbas</Text>,
                    description:
                      <Select
                        mode='multiple'
                        placeholder='Elegir Hierbas'
                        style={{ width: "100%" }}
                        onChange={handleBadHerbs}
                        options={badHerbsOptions}
                        //defaultValue={badHerbs}
                        maxTagCount='responsive'
                        disabled={ (current !== 3) ? true : false}
                      />,
                  },
                ]}
              />

              <Button
                type="primary"
                //style={{ color: "#00628b" }}
                onClick={getResult}
                disabled={ (current !== 3) ? true : false}
              >
                Obtener Resultados
              </Button>

            </Space>
          </Content>

          <Content className="content">
            {(hierbasMezclaBests.length > 0)?
            <Row style={{ marginTop: 30}}>
              <Col xs={0} sm={4} md={4} lg={6} xl={7} ></Col>
              <Col xs={24} sm={16} md={16} lg={12} xl={10} >
                <Title level={4}>Dos mezclas más eficaces contra el conjunto de malas hierbas</Title>
                <Carousel afterChange={onChangeCarouselM} effect="fade" ref={carouselM} >
                  { hierbasMezclaBests.slice(0,2).map((herbicidasM) => (
                    <Card
                      title={(currentCarouselStepM === 0)? "Recomendación más óptima" : "Recomendación óptima"}
                      //extra={<Button shape="circle" icon={<ArrowRightOutlined />} />}
                      /*actions={[
                        <InfoCircleOutlined shape="circle" onClick={() => info(herbicidasM)} style={{ fontSize: 20}} />,
                        (currentCarouselStepM === 0) ?
                          <ArrowRightOutlined shape="circle" onClick={() => handleNextCarousel(carouselM)} style={{ fontSize: 20}} /> :
                          <ArrowLeftOutlined shape="circle" onClick={() => handlePrevCarousel(carouselM)} style={{ fontSize: 20}} />,
                      ]}*/
                      bordered={true}
                      //style={{ marginTop: 16, display: "block", textAlign: "center" }}
                      key={toTitleCase(herbicidasM.herbicideName)}
                    >
                      <b>{toTitleCase(herbicidasM.herbicideName)}</b>
                      <br/>
                      <Rate disabled allowHalf value={getStars(herbicidasM.sum,'mezcla')} />
                      <br/>
                      <Button
                        type="default"
                        onClick={() => info(herbicidasM)}
                      >
                        Info sobre rotación de cultivos
                      </Button>
                      <br/>
                      <br/>
                    </Card>
                  ))}
                </Carousel>
              </Col>
              <Col xs={0} sm={4} md={4} lg={6} xl={7} ></Col>
            </Row>
            : ''}

            {(hierbasSolasBests.length > 0)?
            <Row style={{ marginTop: 30}}>
              <Col xs={0} sm={4} md={4} lg={6} xl={7} ></Col>
              <Col xs={24} sm={16} md={16} lg={12} xl={10} >
                <Title level={4}>Dos herbicidas más eficaces contra el conjunto de malas hierbas</Title>
                <Carousel afterChange={onChangeCarouselS} effect="fade" ref={carouselS} >
                  { hierbasSolasBests.slice(0,2).map((herbicidasS) => (
                    <Card
                      title={(currentCarouselStepS === 0)? "Recomendación más óptima" : "Recomendación óptima"}
                      //extra={<Button shape="circle" icon={<ArrowRightOutlined />} />}
                      /*actions={[
                        <InfoCircleOutlined shape="circle" onClick={() => info(herbicidasS)} style={{ fontSize: 20}} />,
                        (currentCarouselStepS === 0) ?
                          <ArrowRightOutlined shape="circle" onClick={() => handleNextCarousel(carouselS)} style={{ fontSize: 20}} /> :
                          <ArrowLeftOutlined shape="circle" onClick={() => handlePrevCarousel(carouselS)} style={{ fontSize: 20}} />,
                      ]}*/
                      bordered={true}
                      //style={{ marginTop: 16, display: "block", textAlign: "center" }}
                      key={toTitleCase(herbicidasS.herbicideName)}
                    >
                      <b>{toTitleCase(herbicidasS.herbicideName)}</b>
                      <br/>
                      <Rate disabled allowHalf value={getStars(herbicidasS.sum,'solo')} />
                      <br/>
                      <Button
                        type="default"
                        onClick={() => info(herbicidasS)}
                      >
                        Info sobre rotación de cultivos
                      </Button>
                      <br/>
                      <br/>
                    </Card>
                  ))}
                </Carousel>
              </Col>
              <Col xs={0} sm={4} md={4} lg={6} xl={7} ></Col>
            </Row>
            : ''}
          </Content>


          {(recomendationColums && recomendation.length > 0)?
          <Content className="content">
            <Row style={{ marginTop: 30}}>
              <Col xs={0} sm={4} md={2} lg={4} xl={5} ></Col>
              <Col xs={24} sm={16} md={20} lg={16} xl={14} >
                <Title level={4}>Resumen de eficacias por mala hierba</Title>
                <Table columns={recomendationColums} dataSource={recomendation} pagination={false} scroll={{ x: 400, y:400 }} style={{ marginTop: 30}}>
                  <Column dataIndex="herbName" key="herbName" />
                  <Column dataIndex="valueHerbicida0stars" key="valueHerbicida0stars" />
                  <Column dataIndex="valueHerbicida1stars" key="valueHerbicida1stars" />
                  <Column dataIndex="valueHerbicida2stars" key="valueHerbicida2stars" />
                  <Column dataIndex="valueHerbicida3stars" key="valueHerbicida3stars" />
                </Table>
              </Col>
              <Col xs={0} sm={4} md={2} lg={4} xl={5} ></Col>
            </Row>
            <br/><br/>
            <Space>
            <Button
              //type="primary"
              href={(zone.toLowerCase() === 'portugal')?
                      'https://activadekalb.com/pt#flipbook-df_557/1/'
                      :'https://activadekalb.com/es/#flipbook-df_654/1/'
                    }
              target="_blank"
              disabled={ (current !== 3) ? true : false}
            >
              Soluciones Bayer para Maíz
            </Button>
            <Button
              type="primary"
              //href="/herbicidas"
              href='https://herbicidas.activadekalb.com'
              target="_self"
              disabled={ (current !== 3) ? true : false}
            >
              Recalcular
            </Button>
            </Space>
          </Content>
          : ''}

        </div>
      </Content>
      <Footer className="footer"></Footer>
    </Layout>
  </>
  );
};

export default Herbicidas;