import React from 'react';
import { Col, Row, Image, Typography } from 'antd';
import '../assets/css/Header.scss';

function Header(props: { title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) {
  const { Title, Link } = Typography;

  return (
  <>
    <div className="headerBar" style={{ padding: 0}}>
      <Row>
        <Col xs={4} sm={4} md={4} lg={6} xl={6}>
          <Title className="title">
            <Link href="https://www.dekalb.es/" target="_blank">
              <Image className='headerLogoDekalab' preview={false} src="/img/logo1.png"/>
            </Link>
          </Title>
        </Col>
        <Col xs={16} sm={16} md={16} lg={12} xl={12}>
          <Title className="title">ACTIVA DEKALB</Title>
          <p className="subTitle">{props.title}</p>
        </Col>
        <Col xs={4} sm={4} md={4} lg={6} xl={6}>
          <Title className="title">
            <Link href="https://www.cropscience.bayer.es/" target="_blank">
              <Image className='headerLogoBayer' preview={false} src="/img/bayer.png"/>
            </Link>
          </Title>
        </Col>
      </Row>
    </div>
  </>
  );
};

export default Header;