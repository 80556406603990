import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Landing, Herbicidas } from './pages';

const App: React.FC = () => (
  (window.location.host.split(".")[0].trim().toLowerCase().indexOf('herbicidas') !== -1 )?
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Herbicidas />} />
      </Routes>
    </BrowserRouter>
  :
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/herbicidas" element={<Herbicidas />} />
      <Route path="*" element={<Landing />} />
    </Routes>
  </BrowserRouter>
);

export default App;
