import React from 'react';
import { Layout, Space, Typography } from 'antd';
import { Col, Row, Image } from 'antd';
import '../assets/css/Landing.scss';
import Header from './Header';

function App() {
  const { Link, Text } = Typography;
  const { Footer, Content } = Layout;

  return (
  <>
    <Layout className='wrapper'>
      <Header title={'HERRAMIENTAS'} />
      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <Content className="content">
            <Row>
              <Col xs={0} sm={0} md={0} lg={2} xl={6}></Col>
              <Col xs={24} sm={12} md={12} lg={10} xl={6}>
                <div className='contentIconLink'>
                <Link
                  href="https://calculador.activadekalb.com/"
                  target="_self"
                >
                  <Space direction="vertical">
                    <Image className='contentIcon' preview={false} src="/img/maiz.svg"/>
                    <Text className="contentIconText">APP DENSIDADES</Text>
                  </Space>
                </Link>
                </div>
              </Col>

              <Col xs={24} sm={12} md={12} lg={10} xl={6} >
                <div className='contentIconLink'>
                <Link
                  //href="/herbicidas"
                  href='https://herbicidas.activadekalb.com'
                  target="_self"
                >
                  <Space direction="vertical">
                    <Image className='contentIcon' preview={false} src="/img/planta.svg"/>
                    <Text className="contentIconText">APP HERBICIDAS</Text>
                  </Space>
                </Link>
                </div>
              </Col>
              <Col xs={0} sm={0} md={0} lg={2} xl={6}></Col>
            </Row>
          </Content>
        </div>
      </Content>
      <Footer className="footer"></Footer>
    </Layout>
  </>
  );
};

export default App;